<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					:class="isDashboard && ['xs', 'sm'].includes(windowWidth) ? 'm-2' : 'mx-1' "
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<div class="btn btn-lg">
					{{ datePeriod }}
				</div>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			:class="isDashboard && ['xs', 'sm'].includes(windowWidth) ? 'm-2' : '' "
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<template
						v-if="!isDashboard">
						<div :class="dateRange === dateRanges.custom ? 'col-md-3 col-sm-3 col-xs-12' : 'col-md-6 col-sm-6 col-xs-12'">
							<div
								class="form-group">
								<label
									for="uid"
									class="label">{{ translate('user_id') }}</label>
								<input
									id="uid"
									v-model="filters.uids"
									:placeholder="translate('type_uid')"
									type="text"
									name="uids"
									class="form-control">
							</div>
						</div>
					</template>
					<div :class="dateRange === dateRanges.custom ? (isDashboard ? 'col-md-4 col-sm-4 col-xs-12' : 'col-md-3 col-sm-3 col-xs-12') : (isDashboard ? 'col-12' : 'col-md-6 col-sm-6 col-xs-12')">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									v-for="range in dateRanges"
									:key="range"
									:value="range">
									{{ translate(range) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						:class="isDashboard? 'col-md-4 col-sm-4 col-xs-12' : 'col-md-3 col-sm-3 col-xs-12'">
						<label
							for="startDate"
							class="label">{{ translate('start_date') }}</label>
						<v-date-picker
							id="startDate"
							v-model="startDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="startDate"
							show-caps />
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						:class="(isDashboard ? 'col-md-4 col-sm-4 col-xs-12' : 'col-md-3 col-sm-3 col-xs-12') + ' ' + (['xs', 'sm'].includes(windowWidth) ? 'my-2' : '')">
						<label
							for="endDate"
							class="label">{{ translate('end_date') }}</label>
						<v-date-picker
							id="endate"
							v-model="endDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="endDate"
							show-caps />
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('distributor_id')">
							{{ translate('user_id') }}
							<sort field="distributor_id" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('username')">
							{{ translate('username') }}
							<sort field="username" />
						</th>
						<th
							v-if="isAdminOrCorporate"
							class="border-top-0 pointer"
							@click="sortByField('first_name')">
							{{ translate('first_name') }}
							<sort field="first_name" />
						</th>
						<th
							v-if="isDistributor"
							class="border-top-0 pointer"
							@click="sortByField('distance')">
							{{ translate('distance') }}
							<sort field="distance" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('unilevel')">
							{{ translate('unilevel_distributors') }}
							<sort field="unilevel" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('binary')">
							{{ translate('binary_distributors') }}
							<sort field="binary" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('personal_sponsored')">
							{{ translate('personal_sponsored') }}
							<sort field="personal_sponsored" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('customers')">
							{{ translate('customers') }}
							<sort field="customers" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('affiliates')">
							{{ translate('affiliates') }}
							<sort field="affiliates" />
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle">
							<span
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.id.toString() })">
								{{ item.id }}
							</span>
						</td>
						<td class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td
							v-if="isAdminOrCorporate"
							class="align-middle">
							{{ item.attributes.first_name }}
						</td>
						<td
							v-if="isDistributor"
							class="align-middle">
							{{ item.attributes.distance }}
						</td>
						<td class="align-middle">
							{{ item.attributes.unilevel }}
						</td>
						<td class="align-middle">
							{{ item.attributes.binary }}
						</td>
						<td class="align-middle">
							{{ item.attributes.personal_sponsored }}
						</td>
						<td class="align-middle">
							{{ item.attributes.customers }}
						</td>
						<td class="align-middle">
							{{ item.attributes.affiliates }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import { distributor, admin } from '@/settings/Roles';
import { Network as MO, Grids, Report } from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import {
	custom,
	DATE_START_FILTER as dateStartFilter,
	DATE_END_FILTER as dateEndFilter,
	PERIOD_FILTERS,
	V_CALENDAR_CONFIG as calendarConfig,
	MDY_FORMAT as mdyFormat,
} from '@/settings/Dates';
import DataFilter from '@/components/DataFilter';
import DistributorOrganization from '@/util/Network';
import { SEARCH_USERS } from '@/config/endpoint';
import WindowSizes from '@/mixins/WindowSizes';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'DistributorOrganization',
	messages: [MO, Grids, Report],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, DashboardRedirect, WindowSizes],
	data() {
		return {
			organization: new DistributorOrganization(false),
			dateRanges: (({ all_time: allTime, ...filters }) => ({ ...filters, custom }))(PERIOD_FILTERS),
			dateRange: PERIOD_FILTERS.this_month,
			rangeStart: '',
			rangeEnd: '',
			endpoint,
			calendarConfig,
		};
	},
	computed: {
		isDashboard() {
			return this.$route.name === 'DashboardDistributorOrganization';
		},
		datePeriod() {
			return this.getDatePeriod();
		},
		loading() {
			return !!this.organization.data.loading;
		},
		errors() {
			return this.organization.data.errors;
		},
		pagination() {
			return this.organization.data.pagination;
		},
		data() {
			try {
				const { data } = this.organization.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		isDistributor() {
			return this.$user.details().type === distributor;
		},
		isAdminOrCorporate() {
			return admin.includes(this.$user.details().type);
		},
	},
	mounted() {
		const { query } = this.$route;
		if (this.isDashboard) {
			query.uids = this.$route.params.distributorId;
		}
		this.getDistributorOrganization(query);
		this.datesConfig();
	},
	methods: {
		datesConfig() {
			const { query } = this.$route;
			const dateKeys = Object.keys(query);
			if (typeof this.dateRanges[query.dateRange] !== 'undefined') {
				this.dateRange = query.dateRange;
			} else if (typeof query.dateRange !== 'undefined' && !Object.values(this.dateRanges).includes(query.dateRange)) {
				this.dateRange = this.dateRanges.this_month;
				query.dateRange = this.dateRanges.this_month;
			} else if (dateKeys.includes(dateStartFilter) || dateKeys.includes(dateEndFilter)) {
				this.dateRange = this.dateRanges.custom;
			} else if (typeof query.dateRange === 'undefined') {
				this.dateRange = this.dateRanges.this_month;
			}
			return null;
		},
		getDistributorOrganization(filters) {
			this.organization.getDistributorOrganization(filters).then(() => {
				const { start, end } = this.organization.data.response.data.meta.period_dates;
				this.rangeStart = this.$moment(start.date).format(mdyFormat);
				this.rangeEnd = this.$moment(end.date).format(mdyFormat);
			});
		},
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.getDistributorOrganization(options);

			if (this.isDashboard) return;
			this.$router.push({ name: this.$route.name, query: options });
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
	},
};
</script>
